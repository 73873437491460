import { Organisation } from '~organisations/domain';

import logo from './images/logo.png';

const airnzConfig: Organisation = {
  logo: logo,
  additionalOutcomeCaptureFields: {},
  asyncAdditionalOutcomeCaptureFields: {},
  contactAssessmentForms: {},
};

export default airnzConfig;
